<script setup lang='ts'>
  /**
   * data: 商品信息
   * type: 在哪里调用的，有的页面会有多个列表时需要判断
   * url: 跳转页面的url
   * index: 切换规格的时候使用，确认当前商品在外层的位置
   * imgList: 所有的规格数组，配合index可显示选择的规格 （去掉
   * showChange: 是否展示切换按钮，默认展示
   */
  import { storeToRefs } from 'pinia'
  import { useMainStore } from '@/store/'

  const mainStore = useMainStore()

  const { collectIds } = storeToRefs(mainStore)

  const { collectApi } = useApi()

  const props = defineProps({
    data: Object,
    type: Number,
    url: String,
    index: Number,
    imgList: Array,
    hasSelect: Boolean,
    isSelect: Boolean,
    lazy: {
      type: Boolean,
      default: true,
    },
    showChange: {
      type: Boolean,
      default: true
    },
    selects: {
      type: Array,
      default: () => []
    }
  })
  const emit = defineEmits(['handleClear', 'handleSelect'])
  // 图片是否加载完成
  const isLoad = ref(false)
  // 当前选择规格
  // JOCK: 2023/9/2 默认不选中特定规格，所以改为 -1
  const currentSelet = ref(mainStore.specCurrentIndex);
  const listData = computed(() => props.data?.miniSpecs);
  // const loginVisible = useState("loginVisible");


  // 商品的收藏 
  const handleCollect = (goodsId: any,goods: any) => {
    console.log(goods)
    const consumer_id = useCookie('conId').value
    const collectLists = JSON.parse(localStorage.getItem('collectLists'))
    // 如果没登录弹出登录
    if (!mainStore.currentUser) {
      // loginVisible.value = !loginVisible.value
      let param = []
      goods.collectFlag = true
      if(Array.isArray(collectLists)){
        let goodsObj = collectLists.find((v)=> {
          return v.id == goods.id
        })
        console.log(goodsObj)
        if(!goodsObj){
          param = collectLists
          param.push(goods)
          // ElMessage.success('Collection successful')
        }else{
          param = collectLists.filter(item => item.id != goods.id)
          // ElMessage.success('Cancel Collection Successfully')
        }
      }else{
        param.push(goods)
      }
      param.commodityName = param?.commodity_name
      param.referencePrice = param?.reference_price
      param.minPrice = param?.min_price
      localStorage.setItem('collectLists',JSON.stringify(param))
      const mainStore = useMainStore()
			mainStore.getCollectList()
      return
    }
    collectApi.addCollect({
      consumerId: consumer_id,
      goodsId: goodsId
    }).then(res => {
      // ElMessage.success(res.data)
    })
  }

  // 点击删除
  const handleClear = (data: any, i: any) => {
    emit("handleClear", data, i)
  }
  const detailHref = computed(() => {
    let specsId = undefined
    const idx = currentSelet.value
    if(idx > -1){
      console.error(listData.value, currentSelet.value)
      const item = (listData.value[idx]?.schemeId || listData.value[idx]?.specificationsId)
      if(item){
        specsId =  `/${item}`
      }
    }
    return `${props.url}${specsId ? specsId : ''}`
  });
  // 移动端点击卡片
  const handleMobileClick = (data: any, i: any) => {
    if (props.hasSelect) {
      emit("handleSelect", data, i)
    } else {
      toNewPage(detailHref.value);
    }
  }

  // 切换规格
  const changeCurrentSelet = (idx: number) => {
    currentSelet.value = idx
  }
  
  // 封面
  const goodCover = computed(() => {
    // :src="(props.data?.spcsList && props.data?.spcsList.length && props.data?.spcsList[imgList[props.index]]?.spcsInfo.specificationsImage) ? fileUrl + (props.data?.spcsList[imgList[props.index]]?.spcsInfo.specificationsImage[0]?.storageKey) : fileUrl + (props.data?.commodityImage[0]?.storageKey)"
    const data: any = props.data
    // if (data.spcsList &&
    //     data.spcsList.length &&
    //     data.spcsList[currentSelet.value].spcsInfo &&
    //     data.spcsList[currentSelet.value].spcsInfo.specificationsImage &&
    //     data.spcsList[currentSelet.value].spcsInfo.specificationsImage.length
    //   ) {
    //     return fileUrl + data.spcsList[currentSelet.value].spcsInfo.specificationsImage[0].storageKey
    //   }
    if(listData.value?.length && currentSelet.value > -1 && currentSelet.value < listData.value?.length){
      const item = listData.value[currentSelet.value]
      if(item && typeof item === 'object' && item.images?.length){
        if(['xs'].includes(sizeMode.value)){
          return getImg(item.images[0])
        }else{
          return fileUrl + item.images[0].storageKey
        }
        // return fileUrl + item.images[0].storageKey
      }
    }
    if(['xs'].includes(sizeMode.value)){
      return getImg(data.commodityImage[0])
    }else{
      return fileUrl + data.commodityImage[0].storageKey
    }
    // return fileUrl + data.commodityImage[0].storageKey
  })

  function getNumber(item: any): number {
    if(item && typeof item === 'object'){
      if('spcsInfo' in item){
        return item['spcsInfo']?.specificationsPrice || 0
      }else if('specInfo' in item){
        return item['specInfo']?.schemePrice || item['specInfo']?.specificationsPrice || 0
      }
    }
    return 0
  }
  function sortDataList (a: any, b: any): number {
    if(getNumber(a) > getNumber(b)){
      return 1
    }
    if(getNumber(a) < getNumber(b)){
      return -1
    }
    return 0
  }

  const colourList = ref<any[] | undefined>([])
  watch(() => props.data, (newData) => {
    if(Array.isArray(newData?.spcsList)){
      listData.value = newData?.spcsList.sort(sortDataList)
      if(Array.isArray(newData?.colourList) && newData?.colourList?.length === listData.value?.length){
        colourList.value = listData.value?.map(item => {
          const colorItem = newData?.colourList.find((i: any) => {
            return i.id === item.colourId
          })
          if(colorItem){
            return colorItem
          }
          return {}
        })
      }
    }
  }, { immediate: true })

  // 原价
  const originalPrice = computed(() => {
    const data: any = props.data
    if(data && typeof data === 'object'){
      if(Array.isArray(listData.value) && currentSelet.value > -1 && currentSelet.value < listData.value.length){
        const item = listData.value[currentSelet.value]
        return item.originalPrice || 0 
      }
      if(data?.referencePrice){
        return data?.referencePrice
      }
    }
    return 0
  })
  
  // 现价
  const salePrice = computed(() => {
    const data: any = props.data
    if(data && typeof data === 'object'){
      if(Array.isArray(listData.value) && currentSelet.value > -1 && currentSelet.value < listData.value.length){
        const item = listData.value[currentSelet.value]
        return item.price || 0       
      }
      return data?.minPrice || 0
    }
  })
  const value1 = ref(5)
  // 标签列表
  const commodity_label_label_names = computed(() => {
    return props.data?.commodity_label_label_name ?
                props.data.commodity_label_label_name.split(',') :
                []
  })

  const isSelect = computed(() => {
    return props.selects.includes(props.data.id)
  })

  const sizeMode = inject('sizeMode', 'xxl');
</script>

<template>
  <template v-if="['xs'].includes(sizeMode)">
    <NuxtLink class="single-commodity" :to="detailHref">
      <div class="singCard" @click="handleMobileClick(props?.data, props?.index)">
        <div class="singCard-cover">
          <div class="singCard-cover__inner">
            <div v-if="!isLoad" style="width: 100%; height: 100%; position: relative;"></div>
            <img
              :src="goodCover"
              class="single-image block-click"
              :loading="lazy ? 'lazy' : 'eager'"
              fit="cover"
              style="width: 100%; aspect-ratio: 1/1;"
              :alt="props.data?.commodityName"
              @load="isLoad = true"
              @error="isLoad = true"
            />
          </div>

          <!-- 商品右上角爱心 -->
          <div
            v-if="!props.isSelect"
            class="singCard-cover__like"
            @click.stop.prevent="handleCollect(props.data?.id,props.data)"
          >
            <img v-if="collectIds.includes(props.data?.id)" src="~@/assets/licked_icon.png">
            <img v-else src="~@/assets/lickible_icon.png">
          </div>

          <!-- close -->
          <div
            v-if="props.isSelect && !props.hasSelect"
            class="singCard-cover__close"
            @click.stop.prevent="handleClear(props?.data, props?.index)"
          >
            <img src="~@/assets/close_icon.png">
          </div>
        </div>

        <!-- 切换按钮 -->
        <div class="singCard-switchs">
          <div
            v-for="(spcs, inx) in listData"
            :key="`${inx}_colourList`"
            class="singCard-switchs__item"
            @click.stop.prevent="changeCurrentSelet(inx)"
          >
            <div v-if="spcs?.color" :class="['singCard-switchs__item__point', currentSelet === inx && 'singCard-switchs__item__point--select']" :style="{color: spcs?.color}"
              ></div>
            <img v-else-if="spcs?.icon && spcs?.icon.length" :src="fileUrl + (spcs?.icon[0]?.storageKey)" class="singCard-switchs__item__img">
            <img v-if="!spcs?.color && currentSelet === inx" src="@/assets/selected_icon.png" class="singCard-switchs__item__selectimg" />
          </div>
        </div>

        <div class="singCard-title">
          <span>{{ props.data?.commodityName }}</span>
        </div>

        <!-- 价格 -->
        <div class="singCard-price">
          <span class="singCard-price__sale"><span v-if="originalPrice && originalPrice > salePrice">Sale </span>{{ filterCurrency(salePrice) }}</span>
          <span v-if="originalPrice && originalPrice > salePrice" class="singCard-price__was">was <span class="decoration">{{ filterCurrency(originalPrice) }}</span></span>
        </div>

        <!-- check -->
        <div v-if="props.hasSelect" v-show="isSelect" class="singCard-checked">
          <img src="~/assets/checked-bg.png" alt="">
        </div>
      </div>
    </NuxtLink>
  </template>
  <template v-else>
    <NuxtLink class="single-commodity" :to="detailHref">
        <!-- 主图 -->
        <div class="single-image">
          <!-- <div v-if="!isLoad" style="width: 100%; height: 100%; position: relative;"></div> -->
          
          <img
            :src="goodCover"
            class="single-image block-click lazyload"
            :loading="lazy ? 'lazy' : 'eager'"
            fit="cover"
            style="width: 100%; aspect-ratio: 1/1;"
            :alt="props.data?.commodityName || props.data?.commodity_name"
            @load="isLoad = true"
            @error="isLoad = true"
          />

          <div class="label">
            <el-tag
              v-for="(tag, index) of commodity_label_label_names"
              :key="index"
              :color="index % 2 === 0 ? '#DC226B' : '#78D9E1'"
              :style="{
                color: index % 2 === 0 ? '#FFFFFF' : '#000000',
                border: 'none',
                borderRadius: '0',
                marginRight: '5px',
                height: '30px',
              }"
            >{{ tag }}</el-tag>
          </div>
        
          <!-- 切换按钮 -->
          <div class="change-style-item-group">
            <div
              v-for="(spcs, inx) in listData"
              :key="`${inx}_colourList`"
              class="change-style-item-group-switch"
              @click.stop.prevent="changeCurrentSelet(inx)"
            >
              <div v-if="spcs?.color" :class="['active_circle', currentSelet === inx && 'active_circle--select']" :style="{color: spcs?.color}"
              ></div>
              <img v-else-if="spcs?.icon && spcs?.icon.length" :src="fileUrl + (spcs?.icon[0]?.storageKey)" class="default_circle">
              <img v-if="!spcs?.color && currentSelet === inx" src="@/assets/selected_icon.png" class="default_circle_select" />
            </div>
          </div>

          <!-- 单选按钮 -->
          <el-checkbox v-if="props.hasSelect" class="single-checkbox" :label="props.data?.id" @click.stop>&nbsp;</el-checkbox>
          <!-- close -->
          <div v-if="props.isSelect && !props.hasSelect" class="single-closebox" @click.stop.prevent="handleClear(props?.data, props?.index)">
            <img src="~@/assets/close_icon.png">
          </div>

          <!-- 商品右上角爱心 -->
          <div v-if="!props.isSelect" class="list-grid-like" @click.stop.prevent="handleCollect(props.data?.id,props.data)">
            <img v-if="collectIds.includes(props.data?.id)" src="~@/assets/licked_icon.png">
            <img v-else src="~@/assets/lickible_icon.png">
          </div>
        </div>
        <!-- 商品名称 --> 
        <div class="single-title">
          <NuxtLink :to="`${props.url}${specsId ? specsId : ''}`" class="text-black" style="text-decoration: none">
            {{ props.data?.commodityName || props.data?.commodity_name }}
          </NuxtLink>
        </div>
        <div v-if="props.data?.startRating" class="rating-box">
          <v-rating
            size="small"
            density="compact"
            v-model="props.data.startRating"
            color="#FF9600"
            active-color="#FF9600"
            readonly
          ></v-rating>
        </div>
        <!-- 价格 -->
        <div class="price-box">
          <span class="sale-price"><span v-if="originalPrice && originalPrice > salePrice">Sale </span>{{ filterCurrency(salePrice) }}</span>
          <span v-if="originalPrice && originalPrice > salePrice" class="origina-price">was <span class="decoration">{{ filterCurrency(originalPrice) }}</span></span>
        </div>
    </NuxtLink>
  </template>
</template>

<style lang="scss" scoped>
  .single-commodity {
    width: 100%;
    position: relative;
    cursor: pointer;
    .single-image{
      position: relative;
      height: fit-content;
      text-align: center;
      width: 100%;
    }
    .single-title{
      margin-top: 12px;
      color: #100E31;
      font-size: 14px;
      font-family: Century Gothic;
      font-weight: 400;
      color: #000000;
      line-height: 20px;
      height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .change-style-item-group {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    &-switch {
      position: relative;
      width: 18px;
      height: 18px;
      margin: 0 0;
      display: flex;
      align-items: center;
      justify-content: center;

      & + & {
        margin-left: 10px;
      }
      .default_circle{
        display: block;
        width: 18px;
        height: 18px;
      }

      .default_circle_select {
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;
      }

      .active_circle{
        position: relative;
        display: block;
        width: 18px;
        height: 18px;
        z-index: 9;
        border-radius: 50%;

        &--select {
          outline: 1px solid currentColor;
        }

        &::after {
          display: block;
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: currentColor;
        }
      }
    }
  }

  .list-grid-like {
    position: absolute;
    top: 20px;
    right: 20px;
    img {
      width: 24px;
      height: auto;
    }
  }

  .price-box {
    margin-top: 12px;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    .rating-box+& {
      margin-top: 0;
      margin-bottom: 12px;
    }

    .origina-price {
      margin-left: 29px;
      font-size: 18px;
      font-family: Century Gothic;
      font-weight: bold;
      color: #AAAAAA;
      text-decoration: none;
      
      .decoration {
        text-decoration: line-through;
      }
    }

    .sale-price {
      font-size: 18px;
      font-family: Century Gothic;
      font-weight: bold;
      color: #DC226B;
    }
  }

  .label {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 3;

    .el-tag {
      border-radius: 4px !important;
    }
  }

  .single-checkbox {
    position: absolute;
    top: 20px;
    right: 20px;

    :deep() {
      .is-checked .el-checkbox__inner {
        background-color: #78d9e1;
        border: none;

        &::after {
          display: none;
        }
      }

      .el-checkbox__inner {
        width: 20px;
        height: 20px;
      }

      .el-checkbox__label {
        display: none;
      }
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .single-closebox {
    display: none;
    position: absolute;
    top: 20px;
    right: 20px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .single-commodity {
    &:hover {
      .single-closebox {
        display: block;
      }
    }
  }
</style>

<style scoped lang="scss" src="./ProductListItem.mobile.scss"></style>